/* global moment */

angular.module('LeasePilot').controller('ManageFormsController', [
  '$scope',
  '$mdDialog',
  'FormService',
  '$http',
  '$timeout',
  'SocketService',
  function($scope, $mdDialog, FormService, $http, $timeout, SocketService) {
    $scope.init = function(companyId) {
      $scope.forms = [];
      FormService.query({
        companyId: companyId,
        testing: true,
        manage: true,
      }).then(response => {
        $scope.forms = _.filter(response, form => form.version !== '99999');
        $scope.testForms = _.filter(response, form => form.version === '99999');
        $scope.safeApply();

        // init testing forms
        for (var i = 0; i < $scope.testForms.length; i++) {
          var testForm = $scope.testForms[i];

          var form = _.find(
            $scope.forms,
            f =>
              testForm.name == f.name &&
              testForm.dealType == f.dealType &&
              testForm.flavor == f.flavor,
          );

          if (form) {
            form.testingForm = testForm;
          }
        }

        // init latest tasks
        for (var i = 0; i < $scope.forms.length; i++) {
          var form = $scope.forms[i];
          form.createdAtFormatted = moment(form.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
          form.updatedAtFormatted = moment(form.updatedAt).format('YYYY-MM-DDTHH:mm:ssZ')

          initializeFormTasks(form);
        }
      });
    };

    function getRunningSmlTask(form) {
      if (!form) {
        return;
      }
      if (form.uploadState === 'in_upload') {
        if (
          form.latestTask &&
          (form.latestTask.operation === 'create_sml' ||
            form.latestTask.operation === 'update_sml' ||
            form.latestTask.operation === 'testing_sml')
        ) {
          return form.latestTask;
        }
      }
    }

    function getRunningBuildingTask(form) {
      if (!form) {
        return;
      }
      if (form.uploadState === 'in_upload') {
        if (
          form.latestTask &&
          (form.latestTask.operation === 'create_building_sml' ||
            form.latestTask.operation === 'update_building_sml' ||
            form.latestTask.operation === 'testing_building_sml')
        ) {
          return form.latestTask;
        }
      }
    }

    function initializeFormTasks(form) {
      form.runningSMLTask = getRunningSmlTask(form) || getRunningSmlTask(form.testingForm);
      form.runningBuildingTask = getRunningBuildingTask(form) || getRunningBuildingTask(form.testingForm);
    }

    function updateForm(form, data) {
      data = new FormService(data);
      _.each(_.keys(data), key => {
        form[key] = data[key];
      });

      initializeFormTasks(form);
    }

    function updateTestingForm(form, newTestingForm) {
      form.testingForm.uploadState = newTestingForm.upload_state;
      form.testingForm.latestTask = newTestingForm.latest_task;
      initializeFormTasks(form);
    }

    $scope.subscribeChannel = function(form) {
      SocketService.subscribe('AssetsTaskChannel', form, data => {
        console.log('message recieved: ', form.flavor, data);
        if (data.form) {
          if (data.form.version == '99999') {
            updateTestingForm(form, data.form);
          } else {
            updateForm(form, data.form);
          }
          $scope.safeApply();
        }
      });
    };

    // UPLOAD / UPDATE TASKS

    function confirmUploadSML(msg, confirmCallback) {
      const confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          `${'<div class="confirm-modal md-dialog-content">' +
            '<p class="title" >The file you are attempting to update does not match the selected form.<br><br>'}${msg}<br><br>\
            Do you want to upload it anyway?` +
          '<div class="modal-actions">' +
          '<button class="btn blue-outline" ng-click="dialog.hide()">Yes</button>' +
          '<button class="btn blue active" ng-click="dialog.abort()">No</button>' +
          '</div>' +
          '</div>',
      });

      $mdDialog.show(confirm).then(
        () => {
          confirmCallback();
        },
        () => {
          // Do nothing.
        },
      );
    }

    function uploadAnyway(id) {
      $mdDialog.cancel();
      angular.element(document.getElementById(id)).removeAttr('disabled');
      $timeout(() => {
        angular.element(document.getElementById(id)).click();
        angular
          .element(document.getElementById(id))
          .attr('disabled', 'disabled');
      });
    }

    function getFileNameErrors(fileName, form, operation, companyName) {
      let errorMsg;
      const part0 = companyName;
      const part1 = form.name.toLowerCase();
      let part2;
      let version;

      switch (operation) {
        case 'create_sml': {
          part2 = form.dealType;
          version = parseInt(form.version) + 1;
          break;
        }
        case 'update_sml': {
          part2 = form.dealType;
          version = parseInt(form.version);
          break;
        }
        case 'create_building_sml': {
          part2 = 'buildings';
          version = parseInt(form.buildingVersion) + 1;
          break;
        }
        case 'update_building_sml': {
          part2 = 'buildings';
          version = parseInt(form.buildingVersion);
          break;
        }
        case 'testing_sml':
        case 'testing_building_sml': {
          part2 = 'testing';
          version = 99999;
          break;
        }
      }

      const parts = fileName.split('_');
      if (
        parts[0] !== part0 ||
        parts[1] !== part1 ||
        parts[2] !== part2 ||
        parseInt(parts[parts.length - 1]) !== version
      ) {
        errorMsg = 'The file name is expected to be: ';
        errorMsg += `${_.join(
          [
            part0,
            part1,
            part2,
            '[flavor]',
            _.padStart(version.toString(), 5, '0'),
          ],
          '_',
        )}.docx`;
      }
      return errorMsg;
    }

    function executeUpload(file, form, operation, fileName, description) {
      const formData = new FormData();
      const docType = _.toLower(form.name);
      formData.append('company_id', form.companyId);
      formData.append('description', description);
      formData.append('document_type', docType);
      formData.append('flavor', form.flavor);
      formData.append(fileName, file);
      formData.append('operation', operation);
      formData.append('form_id', form.id);

      $http({
        method: 'POST',
        url: '/api/testing_assets_tasks',
        headers: {
          'Content-Type': undefined,
        },
        data: formData,
        transformRequest: angular.identity,
      }).then(
        response => {
          console.log('success');
        },
        () => {
          console.log('error');
        },
      );
    }

    $scope.confirmBeforeUpdateSML = function(event, form, notAllowed) {
      const id = $(event.target)
        .closest('label')
        .attr('for');

      let msg;
      let buttons;
      if (notAllowed) {
        return;
      }
      if (form.updatesCount) {
        msg =
          'Unattended language updates exist for the selected form, please apply them and mark them as done before continuing.';
        buttons =
          '<button class="btn blue active" ng-click="cancel()">OK</button>';
      } else if (form.leasesCount) {
        msg =
          'The system identified documents created from the Form you are trying to update, this will override existing documents and can create inconsistent results, are you sure?';
        buttons =
          '<button class="btn blue-outline" ng-click="upload()">Yes</button><button class="btn blue active" ng-click="cancel()">No</button>';
      } else {
        angular.element(document.getElementById(id)).removeAttr('disabled');
        return;
      }

      const confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          `${'<div class="confirm-modal md-dialog-content">' +
            '<p class="title" >'}${msg}<br><br>` +
          `<div class="modal-actions">${buttons}</div>` +
          '</div>',
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
      });

      $mdDialog.show(confirm);

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.upload = function() {
          uploadAnyway(id);
        };
      }
    };

    $scope.confirmBeforeUploadSML = function(event, form, notAllowed) {
      const id = $(event.target)
        .closest('label')
        .attr('for');

      if (notAllowed) {
        return;
      }
      if (!form.updatesCount) {
        angular.element(document.getElementById(id)).removeAttr('disabled');
        return;
      }

      const confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          '<div class="confirm-modal md-dialog-content">' +
          '<p class="title" >Unattended language updates exist for the selected form, please apply them and mark them as done before continuing.<br><br>' +
          '<div class="modal-actions">' +
          '<button class="btn blue active" ng-click="cancel()">OK</button>' +
          '</div>' +
          '</div>',
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
      });

      $mdDialog.show(confirm);

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.upload = function() {
          uploadAnyway(id);
        };
      }
    };

    $scope.uploadSML = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'Create new version of the form',
      );
    };

    $scope.updateSML = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'Update existing version of the form',
      );
    };

    $scope.updateTestSML = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'Update 99999 version of the form',
      );
    };

    $scope.uploadBuildingLanguage = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'New version of building language',
      );
    };

    $scope.updateBuildingLanguage = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'Update existing version of building language',
      );
    };

    $scope.updateTestBuildingLanguage = function(
      file,
      form,
      operation,
      fileName,
      companyName,
    ) {
      uploadValidSML(
        file,
        form,
        operation,
        fileName,
        companyName,
        'Update 99999 version of building language',
      );
    };

    function uploadValidSML(
      file,
      form,
      operation,
      fileName,
      companyName,
      description,
    ) {
      if (!file) return;
      const fileNameErrors = getFileNameErrors(
        file.name,
        form,
        operation,
        companyName,
      );
      if (fileNameErrors) {
        confirmUploadSML(fileNameErrors, () => {
          executeUpload(file, form, operation, fileName, description);
        });
      } else {
        executeUpload(file, form, operation, fileName, description);
      }
    };

    $scope.showUploadSMLErrorsDialog = function(
      event,
      smlErrorMsg,
      buildingErrorMsg,
    ) {
      smlErrorMsg = smlErrorMsg || '';
      buildingErrorMsg = buildingErrorMsg || '';
      $mdDialog.show({
        template:
          `${'<md-dialog><upload-sml-errors-modal ' +
            'sml-error-msg="'}${smlErrorMsg}" ` +
          `building-error-msg="${buildingErrorMsg}">` +
          '</upload-sml-errors-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };

    // FORMS

    $scope.showNewFormDialog = function(companyId) {
      const template =
        `<md-dialog><new-form-modal company-id='${companyId}'>` +
        '</new-form-modal></md-dialog>';

      $mdDialog.show({
        template,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };

    $scope.showDeleteFormDialog = function(form) {
      const template =
        `<md-dialog><delete-form-modal company-id='${form.companyId}' name='${form.name}' deal-type='${form.dealType}' flavor='${form.flavor}'>` +
        '</delete-form-modal></md-dialog>';

      const confirm = $mdDialog.confirm({
        template,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });

      $mdDialog.show(confirm).then(() => {});
    };

    $scope.showNewAbstractDialog = function(form) {
      var template =
        '<md-dialog>' +
        '<new-abstract-modal>' +
        '</new-abstract-modal></md-dialog>';

      openDialog(template, { parentForm: form });
    };

    // BLANKS

    $scope.showNewBlankFormDialog = function(companyId, formId) {
      const template =
        `<md-dialog><new-blank-form-modal company-id=${companyId} form-id='${formId}'>` +
        '</new-form-modal></md-dialog>';

      openDialog(template);
    };

    $scope.showDeleteBlankDialog = function(blankId, blankName) {
      const template =
        `${'<md-dialog>' +
          "<delete-blank-modal blank-id='"}${blankId}' blank-name='${blankName}' >` +
        '</delete-blank-modal></md-dialog>';

      openDialog(template);
    };

    // COMMON

    function openDialog(template, data) {
      $mdDialog.show({
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
        template,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        if (data) {
          _.each(data, (val, key) => {
            $scope[key] = val;
          });
        }
      }
    }
  },
]);
